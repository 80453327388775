
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import axios from 'axios';
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import supplyExternal from "../scripts/supplyExternal.js";

export default defineComponent({
  name: "supply-login",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label(translate("user")),
      password: Yup.string().min(4).required().label(translate("password")),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      
      // Send login request
      supplyExternal.methods.getIP(function(ip){return});
      setTimeout(() => {
        values.ip = store.getters.getIP;
        const params = { params: values };
        const host = ApiService.vueInstance.axios.defaults.baseURL;
        axios.get(host+"/o/headless-supply-box/v1.0/users/login", params).
          then(response => {

            if(response.data.email === values.email) {
              store.dispatch(Actions.LOGIN, response.data);
              router.push("/supply");
            }
            else {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: translate("tryAgain"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
          }).catch(e => console.log(e));

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      translate
    };
  },
});
